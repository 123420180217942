<template>
  <div class="container">
    <div class="top">
      <div @click="$router.back()">
        <i class="el-icon-arrow-left" style="font-size: 20px"></i
        ><span>返回</span>
      </div>
      <span>祖地族谱</span>
      <p style="width: 50px"></p>
    </div>
    <div class="box">
      <div class="search">
        <el-select
          v-model="placeAddress"
          placeholder="请选择"
          @change="onChangeAddress"
          style="margin-right: 10px; width: 100px"
        >
          <el-option
            v-for="item in addressList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          style="width: 200px"
          v-model="title"
          placeholder="请输入中文题名"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSearch"
          ></el-button
        ></el-input>
      </div>
      <el-table
        :data="tableData"
        border
        v-loading="loading"
        height="calc(100vh - 160px)"
        style="width: 100%"
        :header-cell-style="{
          background: '#4f81bd',
          color: '#fff',
        }"
      >
        <el-table-column prop="surname" label="姓氏" width="100px">
        </el-table-column>
        <!-- <el-table-column prop="sourceID" label="sourceID" width="240px">
        </el-table-column> -->
        <el-table-column prop="title" label="题名" width="240px">
        </el-table-column>
        <el-table-column prop="area" label="地区" width="140px">
        </el-table-column>
        <el-table-column prop="version" label="版本"> </el-table-column>
        <el-table-column prop="pages" label="页数" width="140px">
        </el-table-column>
        <el-table-column prop="dTime" label="时间" width="140px">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="140px">
          <template slot-scope="scope">
            <!-- <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看</el-button
            > -->
            <el-button @click="gotoEbook(scope.row)" type="text" size="small"
              >原图</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.memberTreeFinished"
              @click="goToTree(scope.row.sourceID)"
              >世系树</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      style="
        width: 100%;
        overflow: auto;
        height: 50px;
        display: flex;
        align-items: center;
      "
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
    
    <script>
import myHeader from "../../components/header/index.vue";
// import { getGenealogy } from "../../api/literature.js";
import { getGenealogy } from "@/api/drillingIn.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      title: "",
      addressList: [],
      loading: false,
      placeAddress: "all",
    };
  },
  created() {
    if (
      !this.isMobileFn() &&
      this.$route.path === "/ancestralGenealogyMobile"
    ) {
      this.$router.push({
        path: "/ancestralGenealogy",
        query: this.$route.query,
      });
    }
    this.pageIndex = 1;
    this.addressList =
      this.$store.state.user.addressList[this.$route.query.question];
    this.getTableList();
  },
  methods: {
    isMobileFn() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return Boolean(flag);
    },
    goToTree(sourceID) {
      let routeData = this.$router.resolve({
        path: this.isMobileFn()
          ? "/treeMobile/" + sourceID
          : "/tree/" + sourceID,
      });
      window.open(routeData.href, "_blank");
    },
    onChangeAddress(e) {
      this.placeAddress = e;
      this.pageIndex = 1;
      this.getTableList();
    },
    async getTableList() {
      this.loading = true;
      let tempAddress = "";
      if (this.placeAddress === "all" && this.addressList.length) {
        tempAddress = this.addressList.slice(1).reduce((prev, next) => {
          return prev ? prev + "," + next.value : next.value;
        }, "");
      }
      try {
        const res = await getGenealogy({
          PageIndex: this.pageIndex,
          PageSize: 8,
          AcientPlaceAddress:
            this.placeAddress === "all" ? tempAddress : this.placeAddress,
          Title: this.title,
        });
        this.tableData = res.items;
        this.total = res.totalCount;
        // if (this.total === 0) {
        //   const res2 = await GetPhoneNumber({
        //     area: this.placeAddress === "all" ? tempAddress : this.placeAddress,
        //   });
        //   this.phoneNumberList = res2.items;
        // }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getTableList();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getTableList();
    },
    onSearch() {
      this.pageIndex = 1;
      this.getTableList();
    },
    handleClick(data) {
      let routeData = this.$router.resolve({
        path: "/genealogyInfo",
        query: {
          sourceID: data.sourceID,
        },
      });
      window.open(routeData.href, "_blank");
    },
    gotoEbook(data) {
      let routeData = this.$router.resolve({
        path: "/imgMobile",
        query: {
          sourceID: data.sourceID,
          id: data.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
    
    <style scoped lang="scss">
.container {
  padding-top: 50px;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    border-bottom: 1px solid #000;
    background: #fff;
    z-index: 9;

    span {
      font-size: 18px;
    }
  }
  .box {
    width: 100%;
    padding: 10px 0 0 0;
    margin: 0 auto;

    .search {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 100px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .table {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #1a416c;
      background: #eee;
    }
    .name {
      display: flex;
      flex-wrap: wrap;
      background: #fff;

      .item {
        width: 250px;
        padding: 20px 5px;
        text-decoration: underline;
        color: #4c69bb;
        cursor: pointer;
        font-size: 16px;
      }

      .item:hover {
        color: red;
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>